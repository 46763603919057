import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { Link } from "gatsby";
import {
  Grid,
  GridItem,
  Typography,
  Box,
  Accordion,
  AccordionItem,
} from "@mintuz/marvel";
import { GhostButton } from "../components/GhostButton";
import { Layout } from "../components/layout";
import { HeaderStyled, HeaderContentStyled } from "../components/HeaderStyled";
import { Logo } from "../components/Logo";

const GridWrap = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  ${css({
    paddingLeft: [4],
    paddingRight: [4],
  })}
`;

const GridWrapSmall = styled(GridWrap)`
  max-width: 700px;
`;

const ColourLink = styled(Link)`
  text-decoration: none;

  ${css({
    color: "purple",
  })}

  &:hover {
    text-decoration: underline;
  }
`;

export default () => {
  return (
    <Layout>
      <HeaderStyled>
        <GridWrap>
          <Grid>
            <GridItem width={[1 / 1]}>
              <Box
                pt={[[4]]}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Link to="/">
                  <Logo />
                </Link>
                <GhostButton as={Link} to="/workouts" variant="secondary">
                  Free Workouts
                </GhostButton>
              </Box>
            </GridItem>
            <GridItem width={[1 / 1]}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="40vh"
              >
                <HeaderContentStyled color="white">
                  <Typography as="h1" variant="displayOne" mb={[6]}>
                    Frequently Asked Questions
                  </Typography>
                </HeaderContentStyled>
              </Box>
            </GridItem>
          </Grid>
        </GridWrap>
      </HeaderStyled>
      <Box pt="100px" pb={[6]}>
        <GridWrapSmall>
          <Accordion>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  How do I start a Group Workout?
                </Typography>
              }
            >
              <Typography as="p" mb={2}>
                Group Workouts are supported in iOS 15 and above via Apple
                SharePlay.
              </Typography>
              <Typography as="p" mb={0}>
                To start a Group Workout, join a FaceTime call and begin a
                workout within the app. The SharePlay start icon should appear
                within the top section of the screen.
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  How do I use the Plate Calculator?
                </Typography>
              }
            >
              <Typography as="p" mb={0}>
                The plate calculator requires iOS 15 and above.
              </Typography>
              <Typography as="p" mb={0}>
                To use the plate calculator, you will require Workout Plan Bot
                Premium and to be within an active workout.
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  Can I share workout routines with friends and family?
                </Typography>
              }
            >
              <Typography as="p" mb={2}>
                Yes, if you have premium you can force touch (long press) a
                routine and click Share.
              </Typography>
              <Typography as="p" mb={0}>
                This will generate a link you can then share and if the
                recipient has the app installed, will automatically open within
                the app.
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  I am a personal trainer, can I use the app to manage my
                  clients?
                </Typography>
              }
            >
              <Typography as="p" mb={0}>
                The app currently supports sharing of routines and workouts with
                friends and family and we will be building on this functionality
                to support personal trainers with additional features in the
                future.
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  When will the App support Apple Watch?
                </Typography>
              }
            >
              <Typography as="p" mt={0} mb={0}>
                Their are plans to support the Apple Watch in the future as a
                standalone app.
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  Will the App support Android devices?
                </Typography>
              }
            >
              <Typography as="p" mb={0}>
                Their are currently no plans to support Android devices at this
                moment in time.
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  Where can I find the Terms of Service?
                </Typography>
              }
            >
              <Typography as="p" mb={0}>
                You can find the{" "}
                <ColourLink to="/terms">terms of service here</ColourLink>
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  Where can I find the Privacy Policy?
                </Typography>
              }
            >
              <Typography as="p" mb={0}>
                You can find the{" "}
                <ColourLink to="/privacy">privacy police here</ColourLink>
              </Typography>
            </AccordionItem>
            <AccordionItem
              header={
                <Typography mt={0} mb={0} as="h2">
                  How can I get in contact with the developer?
                </Typography>
              }
            >
              <Typography as="p" mb={2}>
                You can talk directly to the developer on Discord by following
                <a href="https://discord.gg/CVUnhXnBTT">this invite link</a>.
              </Typography>
              <Typography as="p" mb={0}>
                You can also get in contact with the developer by direct
                messaging on Twitter by following the{" "}
                <a href="https://twitter.com/GetWorkoutApp">GetWorkoutApp</a>{" "}
                account.
              </Typography>
            </AccordionItem>
          </Accordion>
        </GridWrapSmall>
      </Box>
    </Layout>
  );
};
